import { gql } from '@apollo/client';

export const QUERY = {
	TERMS_OF_SERVICE: gql`
        query termsOfService($language: String!) {
            # page: 1 - Smart Faktor
            # page: 2 - Finteq
            termsOfService(page: 2, language: $language) {
                edges {
                    node {
                        name
                        content
                        version
                    }
                }
            }
        }
    `,
	CHECK_FINTEQ_CLIENT: gql`
    query checkFinteqClient($text: String!) {
        checkFinteqClient(text: $text) {
            fullName
            nipId
        }
    }
    `,
	SECURE_FINANCING_DESC: gql`
        query{
            secureFinancing{
                finteqDescription
                creditDescription
                factoringDescription
                loanDescription
            }
        }
    `,
	SECURE_FINANCING_ROWS: gql`
        query{
            secureFinancing{
                rows{
                    category{
                        name
                    }
                    name
                    finteqValue
                    creditValue
                    factoringValue
                    loanValue
                }
            }
        }
    `,
	HOME_PAGE_POSTS: gql`
      query homePagePosts {
          homePagePosts {
              id,
              section,
              title,
              titleWidth,
              subtitle,
              subtitleWidth,
              description,
              descriptionWidth,
              tileType,
              tileBackgroundImage,
              tileBackgroundImageMobile,
              readingTime,
              tileHeight,
              tileHeightMobile,
              redirectToLink,
              industries {
                  id,
                  name,
              },
              category {
                  id,
                  name,
              }
              videoLink,
              backgroundColor
          }
      }
	`,
	HOME_PAGE_CATEGORIES: gql`
      query homePageCategories {
          homePageCategories {
              id,
              name
          }
      }
	`,
	PLAN_SECTION_DATA: gql`
      query planSelectionData {
          planSelectionData {
              vatValue
              recipient
              bankAccountNumber
              termsOfServiceTitle
              termsOfServiceContent
              termsOfServiceVersion
              marketingConsentTitle
              marketingConsentContent
              marketingConsentVersion
          }
      }
	`,
	ALL_FAQ: gql`
      # zaproszenie-dostawcy, cennik
      query getFaq($category: String) {
          faq(category: $category) {
              id
              question
              answer
          }
      }
	`,
	ALL_CURRENCIES: gql`
      query allCurrencies {
          allCurrencies {
              edges {
                  node {
                      id,
                      name,
                      code
                  }
              }
          }
      }
	`,
	ALL_PLAN_DURATIONS: gql`
      query allPlanDurations {
          allPlanDurations {
              id
              duration
              durationUnit
              durationInMonths
          }
      }
	`,
	ALL_COUNTRIES: gql`
      query allCountries {
          allCountries {
              edges {
                  node {
                      id,
                      name,
                      code
                  }
              }
          }
      }
	`,
	ALL_PLAN_SETUPS: gql`
      query allAcademy {
          allPlanSetups {
              name
              id
              description
              pricingSetting {
                  currency {
                      id
                      code
                  }
                  id
                  price
                  promoPrice
                  priceDescription
                  planDuration {
                      duration
                      durationUnit
                  }
              }
              overviewCategories {
                  category
                  overviewOptions {
                      optionType
                      name
                  }
              }
              configCategories {
                  category
                  configOptions {
                      id
                      methodOfCalculation
                      optionType
                      customMethod
                      constantValue
                      translatedValue
                      details {
                          name
                          tooltip
                      }
                      optionCostForCurrency {
                          promoCost
                          cost
                      }
                  }
              }
          }
      }
	`,
	CONFIG_TABLE: gql`
      query configTable {
          configTable {
              category
              options {
                  id
                  name
                  tooltip
                  planConfigOptions {
                      constantValue
                      translatedValue
                      optionType
                      planSetup {
                          name
                      }
                  }
              }
          }
      }
	`
};

export const MUTATION = {
	INVITE_ANIMATOR: gql`
      mutation inviteAnimatorForm(
          $page: String!,
          $source: String!,
          $sourceId: String!,
          $phone: String!,
          $marketingConsent: Boolean,
          $marketingConsentContent: String,
          $clientMutationId: String,
          $browserDetails: String,
          $referrer: String,
          $fullName: String!,
          $nipId: String!,
          $animators: String!,
      ) {
          inviteAnimatorForm(
              input: {
                  page: $page,
                  source: $source,
                  sourceId: $sourceId,
                  phone: $phone,
                  fullName: $fullName,
                  referrer: $referrer,
                  browserDetails: $browserDetails,
                  marketingConsent: $marketingConsent,
                  marketingConsentContent: $marketingConsentContent,
                  clientMutationId: $clientMutationId,
                  animators: $animators,
                  nipId: $nipId,
              }
          ) {
              errors { field, messages }
          }
      }
	`,
	LEAD_FORM: gql`
      mutation leadForm(
          $page: String!
          $source: String!
          $sourceId: String!
          $firstName: String!
          $email: String!
          $phone: String!
          $nipId: String!
          $message: String!
          $additionalInformation: String
          $marketingConsent: Boolean
          $marketingConsentContent: String
          $clientMutationId: String
          $browserDetails: String
          $referrer: String
      ) {
          leadForm(input: {
              page: $page
              source: $source
              sourceId: $sourceId
              nipId: $nipId
              message: $message
              firstName: $firstName
              email: $email
              phone: $phone
              referrer: $referrer
              additionalInformation: $additionalInformation
              browserDetails: $browserDetails
              marketingConsent: $marketingConsent
              marketingConsentContent: $marketingConsentContent
              clientMutationId: $clientMutationId
          }) {
              errors { field, messages }
          }
      }
	`,
	FINTEQ_CONTACT_FORM: gql`
      mutation finteqContactForm(
          $page: String!
          $source: String!
          $sourceId: String!
          $email: String!
          $phone: String!
          $messageTitle: String!
          $copyMessage: Boolean!
          $marketingConsent: Boolean
          $marketingConsentContent: String
          $clientMutationId: String
          $browserDetails: String
          $referrer: String
          $messageContent: String!
          $callMe: Boolean
      ) {
          finteqContactForm(input: {
              page: $page
              source: $source
              sourceId: $sourceId
              email: $email
              phone: $phone
              referrer: $referrer
              browserDetails: $browserDetails
              marketingConsent: $marketingConsent
              marketingConsentContent: $marketingConsentContent
              clientMutationId: $clientMutationId
              messageTitle: $messageTitle
              messageContent: $messageContent
              callMe: $callMe
              copyMessage: $copyMessage
          }) {
              errors { field, messages }
          }
      }
	`,
	PLAN_FORM: gql`
      mutation planOrder(
          $page: String!
          $source: String!
          $sourceId: String!
          $planSetup: ID!
          $duration: ID!
          $firstName: String!
          $email: String!
          $totalNetCost: Float!
          $totalGrossCost: Float!
          $companyName: String!
          $country: ID!
          $buildingNumber: String!
          $street: String!
          $postalCode: String!
          $city: String!
          $nip: String!
          $planName: String!
          $currency: ID!
          $paymentMethodId: Int!
          $termsOfServiceAccepted: Boolean!
          $marketingConsentAccepted: Boolean!
          $termsOfServiceVersion: String!
          $marketingConsentVersion: String!
          $bankAccountNumber: String!
          $recipient: String!
          $vatValue: Float!
          $extraOptionsIds: String
          $browserDetails: String
          $referrer: String
          $vat: Boolean
      ) {
          planOrder(input: {
              page: $page
              source: $source
              sourceId: $sourceId
              planSetup: $planSetup
              duration: $duration
              firstName: $firstName
              email: $email
              totalNetCost: $totalNetCost
              totalGrossCost: $totalGrossCost
              companyName: $companyName
              country: $country
              buildingNumber: $buildingNumber
              street: $street
              postalCode: $postalCode
              city: $city
              nip: $nip
              vat: $vat
              vatValue: $vatValue
              planName: $planName
              currency: $currency
              paymentMethodId: $paymentMethodId
              termsOfServiceAccepted: $termsOfServiceAccepted
              marketingConsentAccepted: $marketingConsentAccepted
              termsOfServiceVersion: $termsOfServiceVersion
              marketingConsentVersion: $marketingConsentVersion
              bankAccountNumber: $bankAccountNumber
              recipient: $recipient
              extraOptionsIds: $extraOptionsIds
              browserDetails: $browserDetails
              referrer: $referrer
          }) {
              errors { field, messages }
          }
      }
	`
};
