import { useLocation } from '@reach/router';
import useLocalStorage from 'hooks/useLocalStorage';

export const POLAND = 'pl';

export const currencies = [{ id: 'pl', code: 'PLN' }, { id: 'en', code: 'EUR' }];

function getLanguage(pathname) {
	if(pathname[3] === '/') {
		return pathname.substr(1, 2);
	}
	return 'pl';
}

export default function useLanguage() {
	const [ allCurrencies ] = useLocalStorage('allCurrencies', []);
	// TODO: common data
	const result = Object.keys(allCurrencies)?.map((key) => allCurrencies[key]);

	const location = useLocation();
	const language = getLanguage(location.pathname);
	const languageCode = currencies.find(item => item.id === language).code;
	const currency = result?.find(item => item.code === languageCode);

	return {
		language,
		location,
		currency
	};
}
