import { useLocation } from '@reach/router';
import loadable from '@react-loadable/revised';
import SvgLogo from 'assets/animator/logo.inline.svg';
import SelectTextField from 'components/material-ui/SelectTextField';
import { routes } from 'constants/routes';
import { Link, navigate } from 'gatsby';
import useFormikLite from 'hooks/useFormikLite';
import { useGraphFlags } from 'hooks/useGraphFlags';
import { POLAND } from 'hooks/useLanguage';
import { includes } from 'ramda';
import React, { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import { areEqual } from 'utils/helper';

const cssSelectFlags = css`
  ${tw`bg-grey-E rounded-full z-10`};

  img {
    ${tw`mt-05`};
  }

  fieldset, .Mui-focused {
    border: none;
  }

  .MuiSelect-icon {
    ${tw`mt-03`};
  }

  .MuiSelect-select {
    ${tw`flex pl-07 sm:pl-10 pt-03 sm:pb-06 pr-18! sm:pr-20!`};
  }
`;

const initLang = (isOrigin, location) => {
	const locationLang = isOrigin ? 'pl' : location?.pathname?.substr(1, 2);
	const otherThanDefaultLang = ['pl', 'en'].includes(locationLang);
	return otherThanDefaultLang ? locationLang : 'pl';
};

const Defs = loadable({
	loader: () => import('assets/jsx/DefsIndex'),
	loading: () => <div/>
});

const Header = ({ Icon, hideLanguage, ...props }) => {
	const location = useLocation();
	const flags = useGraphFlags();
	const [availableFlags, setAvailableFlags] = useState(flags);

	const isOrigin = location?.pathname === '/';
	const currentLang = initLang(isOrigin, location);

	useEffect(() => {
		removeNotSupportedFlags();
	}, []);

	const removeNotSupportedFlags = () => {
		const flagsToRemove = [];

		flags?.filter(flag => flag.id !== currentLang)?.map(flag => {
			const entriesFlags = Object.entries(routes[flag.id]);

			for (const [prop, val] of entriesFlags) {
				if (areEqual(String(val).cut('/'), String(routes[POLAND][prop]).cut('/'), String(location?.pathname.cut('/')))) {
					flagsToRemove.push(flag.id);
					break;
				}
			}
		});
		setAvailableFlags(flags?.filter(flag => !includes(flag.id, flagsToRemove)));
	};

	const formAnimator = useFormikLite({
		initialValues: {
			country: currentLang
		}
	});

	const handleOnLanguageChange = ({ target: { value } }) => {
		if (isOrigin) {
			navigate(routes[value].DASHBOARD);
		} else {
			const entryPath = Object.entries(routes[currentLang]).find(([prop, val]) => {
				return location?.pathname?.withSlash().includes(val);
			});

			if (entryPath?.length) {
				const entryProp = entryPath[0];
				navigate(routes[value][entryProp]);
			}
		}
	};

	return (
		<header {...props}>
			<div className="flex mt-15">
				<Link to={routes[currentLang].DASHBOARD} className="pointer">
					<SvgLogo className="w-110 lg:w-120"/>
				</Link>
				{Icon}
			</div>

			{/*TODO: Check performance and move to Planet component if possible */}
			{/* Can cause errors in displaying HTML */}
			<Defs/>

			<div className="sm:mb-0">
				{!hideLanguage && (
					<SelectTextField
						css={[cssSelectFlags]}
						options={availableFlags}
						form={formAnimator}
						name="country"
						variant="outlined"
						hideLabel
						onChange={handleOnLanguageChange}
					/>
				)}
			</div>
		</header>
	);
};

export default Header;
