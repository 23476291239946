import TextField from '@mui/material/TextField';
import tw, { css, styled } from 'twin.macro';

const baseStyles = props => css`
	${tw`bg-transparent`};
	${props.fullWidth && tw`w-full`};
	
	.MuiInput-input {
		padding: 4px 0 4px;
	}
	
	.MuiInputBase-formControl {
    ${tw`rounded-08`};
	}

	.MuiSelect-root {
    ${tw`rounded-08`};
    .MuiSelect-icon {
			${tw`mt-02`};
		}
  }
	
  label {
		${tw`bg-transparent`};
	}
`;

export const StyledTextField = styled(TextField)`
	${baseStyles};
`;
