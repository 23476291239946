import { useState } from 'react';

export default function useFormikLite({ initialValues }) {
	const [formAnimator, setFormAnimator] = useState({
		handleChange: ({ target: { name, value }}) => {
			setFormAnimator(prev => ({ ...prev, values: { [name]: value }}));
		},
		values: initialValues,
		touched: { },
		errors: { },
	});

	return formAnimator;
}
