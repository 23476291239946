import { useQuery } from '@apollo/client';
import useLanguage from 'hooks/useLanguage';

export default function useCommonData(query, variables, options) {
	const { language } = useLanguage();

	const { loading, error, data } = useQuery(query, {
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
		variables,
		context: {
			headers: {
				'Accept-Language': language
			}
		},
		skip: false
	});

	return data ?? { loading, error };
}
