import { useCallback, useEffect, useState } from 'react';

export default function useFieldValidation({ form, name, displayName = name, errorName = name, errors = [], defaultHelper = '' }) {
	const isTouched = form?.touched[displayName];
	const error = form?.errors[errorName];

	const [errorTextFromBe, setErrorTextFromBe] = useState(errors[errorName]);

	useEffect(() => {
		setErrorTextFromBe(errors[errorName]);
	}, [errors]);

	const resetField = useCallback(() => {
		setErrorTextFromBe(null);
	}, [errorTextFromBe]);

	return {
		isError: (isTouched && !!error) || errorTextFromBe,
		helperText: errorTextFromBe ? errorTextFromBe : (isTouched ? (error ? error : defaultHelper) : defaultHelper),
		resetField
	};
}
