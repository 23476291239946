import MenuItem from '@mui/material/MenuItem';
import SvgArrowDown from 'assets/footer/arrow-down.inline.svg';
import { StyledTextField } from 'components/material-ui/_overrideStyles';
import { CONST } from 'constants/enums';
import useFieldValidation from 'hooks/useFieldValidation';
import PropTypes from 'prop-types';
import React from 'react';

export default function SelectTextField({
	loading, disabled, form, name, defaultValue, errors, defaultHelper,
	disabledFirst, hideLabel, options, idName, labelName, labelSecondName, labelFunc, withEmpty, clearItemName, onChange, ...props
}) {
	const outputOptions = options?.map(option => ({ ...option, id: option[idName], label: labelFunc(option[labelName], option[labelSecondName]) }));
	const { isError, helperText, resetField } = useFieldValidation({ form, name, errors, defaultHelper });
	const value = form?.values[name];

	const handleOnChange = event => {
		resetField();

		form?.handleChange(event);
		onChange && onChange(event);
	};

	return (
		<StyledTextField
			select
			name={name}
			data-cy={props['data-cy'] ?? name}
			disabled={loading || disabled}
			value={typeof value !== 'undefined' ? value : defaultValue}
			error={isError}
			helperText={helperText}
			onChange={handleOnChange}
			SelectProps={{
				IconComponent: iconProps => <SvgArrowDown {...iconProps} />
			}}
			inputProps={{
				MenuProps: { disableScrollLock: true }
			}}
			{...props}
		>
			{withEmpty && <MenuItem aria-label="Clear" key="" value={CONST.OPTION.EMPTY}>{clearItemName}</MenuItem>}
			{outputOptions?.map((option, key) => (
				<MenuItem key={option.id} value={option.id} disabled={key === 0 && disabledFirst}>
					{option.publicURL && <img src={option.publicURL} alt="" width={option.width} height={option.height}/>}
					<span>{!hideLabel && option.label}</span>
				</MenuItem>
			))}
		</StyledTextField>
	);
}

SelectTextField.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired
};

SelectTextField.defaultProps = {
	defaultHelper: '',
	defaultValue: '',
	variant: 'standard',
	labelName: 'name',
	labelSecondName: 'name',
	idName: 'id',
	size: 'small',
	clearItemName: 'Wyczyść',
	labelFunc: label => label
};
