// TODO: delete locales items from locales select
// TODO: DASHBOARD have to be last one in object -> method: handleOnLanguageChange = ({ target: { value } })
export const routes = {
	PAGE_NOT_FOUND: '/404',
	pl: {
		BECOME_AN_ANIMATOR: '/zostan-animatorem/',
		DUBAI_PREMIERE: '/zostan-animatorem/',
		SUPPLY_CHAIN_FINANCING: '/finansowanie-lancucha-dostaw/',
		INFLATION: '/pokonaj-inflacje/',
		PRICING: '/cennik-i-plany/',
		UE: '/fundusze-europejskie/',
		BASKET_PRODUCT_OPTIONS_SELECTION: '/koszyk/wybor-opcji-produktu/',
		BASKET_SUMMARY: '/koszyk/podsumowanie/',
		FOR_SUPPLIER: '/zamien-faktury-na-gotowke/',
		FINANCING_FOR_COMPANIES_COMPARISON: '/finansowanie-dla-firm-porownanie/',
		ESG_FINANCING: '/zarzadzanie-plynnoscia-zgodnie-z-esg/',
		CONTACT: '/kontakt/',
		PRIVACY_POLICY: '/polityka-prywatnosci/',
		DASHBOARD: '/'
	},
	en: {
		BECOME_AN_ANIMATOR: '/en/become-an-animator/',
		DUBAI_PREMIERE: '/en/dubai-premiere/',
		SUPPLY_CHAIN_FINANCING: '/en/supply-chain-financing/',
		CONTACT: '/en/contact/',
		INFLATION: '/protect-money-from-inflation/',
		FOR_SUPPLIER: '/cash-your-invoice/',
		FINANCING_FOR_COMPANIES_COMPARISON: '/debt-free-financing/',
		ESG_FINANCING: '/esg-in-supply-chain/',
		PRIVACY_POLICY: '/en/privacy-policy/',
		// all links without translations
		UE: '/fundusze-europejskie/',
		PRICING: '/cennik-i-plany/',
		BASKET_PRODUCT_OPTIONS_SELECTION: '/koszyk/wybor-opcji-produktu/',
		BASKET_SUMMARY: '/koszyk/podsumowanie/',
		DASHBOARD: '/en/'
	}
};
