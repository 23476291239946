import React, { useReducer } from 'react';
import { createContext, useContextSelection } from 'use-context-selection';
import { updateObject } from 'utils/helper';

const PageStateContext = createContext();
const PageDispatchContext = createContext();

const initialState = {
	pageData: null,
};

const actionsPage = {
	updatePageData: 'updatePageData',
};

function mainReducer(state, { type, payload }) {
	switch (type) {
		case actionsPage.updatePageData: {
			return updateObject(state, {
				pageData: payload,
			});
		}
		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
}

function PageProvider({ children }) {
	const [state, dispatch] = useReducer(mainReducer, initialState);

	return (
		<PageStateContext.Provider value={state}>
			<PageDispatchContext.Provider value={dispatch}>
				{children}
			</PageDispatchContext.Provider>
		</PageStateContext.Provider>
	);
}

function usePageState() {
	const context = useContextSelection(PageStateContext, state => state);
	if (context === undefined) {
		throw new Error('usePageState must be used within a PageProvider');
	}
	return context;
}

function usePageDispatch() {
	const context = useContextSelection(PageDispatchContext, state => state);
	if (context === undefined) {
		throw new Error('usePageDispatch must be used within a PageProvider');
	}
	return context;
}

export { PageProvider, usePageState, usePageDispatch, actionsPage };
