import CircularProgress from '@mui/material/CircularProgress';
import Header from 'components/Header';
import useCommonData from 'hooks/useCommonData';
import useLocalStorage from 'hooks/useLocalStorage';
import { QUERY } from 'interfaces/graph';
import PropTypes from 'prop-types';
import { PageProvider } from 'providers/PageProvider';
import * as React from 'react';
import { useEffect } from 'react';

const handleErrorBoundary = (error, info) => {
	console.error('error', error);
	console.info('info', info);
};

// TODO: check fallback
function ErrorFallback({ error, resetErrorBoundary }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	);
}

const Layout = ({ Icon, loading, error, hideLanguage, children, ...props }) => {
	const { allCurrencies } = useCommonData(QUERY.ALL_CURRENCIES);
	const [, setAllCurrencies] = useLocalStorage('allCurrencies');

	// TODO: think about common's data
	useEffect(() => {
		setAllCurrencies(allCurrencies?.edges?.map(item => item.node));
	}, [allCurrencies]);

	return (
		<PageProvider {...props}>
			<Header
				Icon={Icon}
				hideLanguage={hideLanguage}
				className="layout flex items-end justify-between"
			/>
			<main>
				{!loading ? children : (
					<div style={{ height: '85vh' }} className="flex items-center justify-center">
						<CircularProgress/>
					</div>
				)}
			</main>
		</PageProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
