import { graphql, useStaticQuery } from 'gatsby';

const getPropertiesByName = node => {
	switch (node.name) {
		case 'poland': return {
			id: 'pl',
			name: 'Polski',
			width: 25,
			height: 20
		};
		case 'united-kingdom': return {
			id: 'en',
			name: 'Angielski',
			width: 25,
			height: 20
		};
		default: {
			console.warn('getPropertiesByName - file name not found.');
			return {};
		}
	}
};

export const useGraphFlags = () => {
	const { allFile } = useStaticQuery(
		graphql`
        query GraphAllFile {
            allFile(filter: {relativeDirectory: { eq: "flags" }}) {
                nodes {
                    relativePath
                    publicURL
										name
                }
            }
        }
		`
	);
	return allFile.nodes.map(node => ({ ...node, ...getPropertiesByName(node) }));
};
